import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import "../../styles/support.scss";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import Photo from "../../components/Photo";
import "../../styles/accodian.scss";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import SuccessAlert from "../common/SuccessAlert";
import ErrorAlert from "../common/ErrorAlert";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { saveContactDetails } from "../../services/logipulse-crm-service";
import { useGeoLocation } from "../../services/useGeoLocation";

const initialFormValue = {
  Name: "",
  CompanyName: "",
  Email: "",
  MobileNo: "",
  Questions: ""
}

const GEOLOCATION_URL = process.env.GEOLOCATION_URL;

function App() {
  const context = React.useContext(I18nextContext);
  const [selected, setselected] = useState(null);
  const [formValues, setformValues] = useState(initialFormValue);
  const [alert_type, setAlertType] = useState("");
  const [alert_message, setAlertMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { location } = useGeoLocation();

  useEffect(() => {
    let isApiSubscribed = true;

    fetch(GEOLOCATION_URL)
      .then(data => data.json())
      .then(json => {
        if (isApiSubscribed) {
          let geoLocation = json;
          let value = { ...initialFormValue };
          value['MobileNo'] = geoLocation.country_calling_code;
          setformValues(value);
        }
      })
      .catch(err => {});
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  const handleOnChange = e => {
    const { name, value } = e.target
    setformValues({ ...formValues, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (Object.keys(formValues).length > 0) {
        setLoading(true);
        setAlertType("");
        setAlertMessage("");

        saveContactDetails(formValues)
          .then(response => {
            setAlertType("SUCCESS")
            setAlertMessage("Successfully sent Email");
            initialFormValue.MobileNo = location.country_calling_code;
            setformValues(initialFormValue);
            setLoading(false);
          })
          .catch(e => {
            setLoading(false);
            setAlertType("ERROR")
            setAlertMessage("Something went wrong!");
            // initialFormValue.MobileNo = location.country_calling_code;
            // setformValues(initialFormValue);
          });
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const toggle = index => {
    if (selected === index) {
      return setselected(null)
    }
    setselected(index)
  }

  const phoneInputOnChange = (value) => {
    const formData = { ...formValues };
    formData['MobileNo'] = '+' + value;
    setformValues(formData);
  };

  return (
    <div>
      <div className="banner-feature no-supportmargin" lang={context.language}>
        <div className="container container-style">
          <div className="row">
            <div className="col-md-12">
              <div className="overlay-freight"></div>

              <div className="right-img"></div>
            </div>

            <div className="col-md-12">
              <div className="contents-imgs support-contents">
                <div className="pricing_details" lang={context.language}>
                  <Link to="/">
                    <Trans>HOME</Trans>
                  </Link>{" "}
                  /{" "}
                  <span className="view_features">
                    <Trans>SUPPORT</Trans>
                  </span>
                </div>
                <div
                  className="sub_main-product-features sub_content_support"
                  lang={context.language}
                >
                  <Trans>
                    An investment in knowledge pays the best interest
                  </Trans>
                </div>
                <div
                  className="subsub_main supportdetails_main"
                  lang={context.language}
                >
                  <Trans>
                    Anything that is born out of pure passion can go far
                    untiringly to make the outcome perfect, such was the birth
                    and journey of Logipulse!
                  </Trans>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row support_position" lang={context.language}>
          <div className="col-md-6">
            <div className="getfreedemo_contents">
              {/* <!-- multistep form --> */}
              <div className="support_heading_section">
                <div className="supportprofile" lang={context.language}>
                  <b>
                    <Trans>Write to Us</Trans>
                  </b>
                </div>
              </div>

              <fieldset disabled={isLoading}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="supportformFullName"
                    className="position_relative"
                  >
                    <Form.Label className="support_details_label"><Trans>Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <Form.Control
                      type="text"
                      name="Name"
                      value={formValues.Name}
                      className="control-label"
                      required="required"
                      onChange={handleOnChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="supportformFullName"
                    className="position_relative"
                  >
                    <Form.Label className="support_details_label"><Trans>Company Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <Form.Control
                      type="text"
                      name="CompanyName"
                      value={formValues.CompanyName}
                      className="control-label"
                      required="required"
                      onChange={handleOnChange}
                    />
                  </Form.Group>

                  {/* <Form.Group
                    controlId="supportformFullName"
                    className="position_relative"
                  >
                    <Form.Label className="support_details_label"><Trans>Email</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <Form.Control
                      type="text"
                      name="Email"
                      value={formValues.Email}
                      className="control-label"
                      required="required"
                      onChange={handleOnChange}
                    />
                  </Form.Group> */}

                  {/* {<div className="row mb-4 mblmobile">
                    <div className="col"> */}
                  <Form.Group
                    controlId="supportformFullName"
                    className="position_relative"
                  >
                    <Form.Label className="support_details_label"><Trans>Email</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <Form.Control
                      type="email"
                      name="Email"
                      value={formValues.Email}
                      className="control-label"
                      required="required"
                      onChange={handleOnChange}
                    />
                  </Form.Group>
                  {/* <p className="formErrors">{formErrors.email}</p>
                    </div> */}
                  {/* </div>} */}

                  <Form.Group controlId="supportformFullName" className="position_relative">
                    <Form.Label className="support_details_label"><Trans>Mobile Number</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <PhoneInput
                      country={'us'}
                      inputClass="support-details-phone"
                      required="true"
                      enableSearch="true"
                      value={formValues.MobileNo}
                      onChange={phoneInputOnChange}
                    />
                  </Form.Group>

                  {/* <div className="row">
                    <div className="col-5 col-md-3 pr-0">
                      <div className="phone_dropdown">
                        <PhoneInput
                          options={options}
                          setSelectedCountry={setSelectedCountry}
                          selectedCountry={selectedCountry}
                          value={formValues.options}
                        />
                      </div>
                    </div>
                    <div className="col-7 col-md-7">
                      <Form.Group className="position_relative form_parent">
                        <Form.Control input type="number" name="MobileNo" value={formValues.MobileNo}
                          className="control-label" required="required" onChange={handleOnChange} />
                        <label for="MobileNo"><Trans>Mobile Number</Trans></label>
                      </Form.Group>                    
                    </div>
                  </div> */}

                  <Form.Group className="mb-3 position_relative" controlId="supportformFullName">
                    <Form.Label className="support_details_label"><Trans>Questions / Comments</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                    <Form.Control
                      as="textarea"
                      name="Questions"
                      value={formValues.Questions}
                      onChange={handleOnChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="margin_bottom_16"
                    lang={context.language}
                  >
                    <button type="submit" className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading}><Trans>SEND</Trans>
                      {
                        isLoading ? (
                          <Photo src="loader_64.gif" className="spinner" alt="" />
                        ) : null
                      }
                    </button>
                  </Form.Group>

                  {alert_type === "SUCCESS" ? (
                    <SuccessAlert message={alert_message} />
                  ) : null}
                  {alert_type === "ERROR" ? (
                    <ErrorAlert message={alert_message} />
                  ) : null}
                </Form>
              </fieldset>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <ul>
                  <li className="support_livechat" lang={context.language}>
                    <Link to="/">
                      <ForumOutlinedIcon
                        className="support_testing"
                        lang={context.language}
                      />
                      <Trans>Live chat</Trans>
                    </Link>
                  </li>
                  <li className="support_tollfree" lang={context.language}>
                    <a href="tel:+91 9747469023">
                      <LocalPhoneOutlinedIcon
                        className="support_testing"
                        lang={context.language}
                      />
                      <Trans>+ 91 9747469023</Trans>
                    </a>
                  </li>
                  <li className="support_tollfree" lang={context.language}>
                    <a href="mailto:sales@logipulse.com">
                      <EmailOutlinedIcon
                        className="support_testing"
                        lang={context.language}
                      />
                      <Trans>Mail us</Trans>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="supportprofile ipad_text" lang={context.language}>
              <b>
                <Trans>How can we help you?</Trans>
              </b>
            </div>
            <div className="wrapper">
              <div className="accordian">
                {data.map((item, i) => (
                  <div className="item" key={"faq_support" + i}>
                    <div className="antitle " onClick={() => toggle(i)}>
                      <div className="supportQuestion">
                        <Trans>{item.Question}</Trans>
                      </div>
                      <span>{selected === i ? "-" : "+"}</span>
                    </div>
                    <div
                      className={
                        selected === i ? "contentin show" : "contentin"
                      }
                      lang={context.language}
                    >
                      <Trans>{item.answer}</Trans>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end_divis_support">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Photo src="Bottom Image-11.svg" className="innerimage" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const data = [
  {
    Question: "What is FFM software?",
    answer:
      "A Freight Forwarding Management software helps to automate the everyday operations of a freight forwarder including the operations in land, ocean, and air freight, transportation, customs clearance, and finance.",
  },
  {
    Question:
      "Can I try freight forwarding software before deciding on buying it?",
    answer:
      "A good number of freight forwarding solutions companies offer a demo before purchasing the software. At Logipulse, we offer a free trial of 14 days.",
  },
  {
    Question: "How does the free trial work?",
    answer:
      "You can explore all the features of Logipulse for the 14 days free trial",
  },
  {
    Question: "What kind of support will your FFM software team provide?",
    answer:
      "We have a dedicated after-sales service team who are available online and offline too",
  },
  {
    Question: "What if I want to change my freight forwarding software plan?",
    answer: "Yes, it is possible to change your plan",
  },
  {
    Question: "Do I need a credit card to sign up?",
    answer:
      "No, we don’t insist on credit card details when opting for our free trial",
  },
  {
    Question:
      "How is Logipulse different from other freight forwarding software?",
    answer:
      "Logipulse is a cloud-hosted SaaS application, an end-to-end solution for freight forwarding, with superior technology aimed at small to medium freight forwarders",
  },
  {
    Question: "Is Logipulse available in other languages?",
    answer:
      "Yes, Logipulse is the first in having a truly complete multilingual interface, including major East Asian, European, and Middle Eastern languages",
  },
  {
    Question:
      "Can I migrate from my current software system? How easy would it be to switch to Logipulse?",
    answer:
      "You can import your existing database to Logipulse, including your finance software and begin using Logipulse immediately",
  },
  {
    Question: "Is freight forwarding software available on a mobile app?",
    answer:
      "The mobile app for freight forwarders allows to instantly check the status of their freight services. Logipulse freight forwarding software is available on Android and iOS mobiles",
  },
  {
    Question: "Why are dashboards important in Logistics software?",
    answer:
      "An important information management tool, dashboards allow you to monitor, analyze and give you a visual display of KPIs concerning the overall supply chain management. Essentially allows you to find bad data and clean it up.",
  },
]

export default App
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
